/** @jsx jsx */
import { jsx, useThemeUI } from "theme-ui";
import { Fragment, useEffect } from "react";
import { UAnchorLink } from "../../../components/UAnchorLink";
import { Box, Flex, Heading, Text, Link, Button } from "rebass";
import Hero from "../../../components/Hero";
import ProjectTitleListing from "../../../components/ProjectTitleListing";
import ProjectFadeInTitle from "../../../components/ProjectFadeInTitle";
import BackToTop from "../../../components/BackToTop";
import { StaticImage } from "gatsby-plugin-image";
import PlaneScene from "../../../components/PlaneScene";
import Card from "../../../components/Card";
import ChatBubble from "../../../components/ChatBubble";
import UxStrategyDiagram from "../../../icons/UxStrategyDiagram";
import UxStrategyDiagramPXP from "../../../icons/UxStrategyDiagramPXP";
import UxStrategyDiagramSO from "../../../icons/UxStrategyDiagramSO";
import { Modal } from "../../../components/Modal";
import { useModal } from "../../../components/useModal";
import Carousel from "../../../components/Carousel";
import Close from "../../../icons/Close";
import CompanyProjects from "../../../components/CompanyProjects";
import Layout from "../../../layouts/";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import ThemeColorSetter from "../../../components/ThemeColorSetter";

//Assets
import imgUserTesting1 from "../../../img/bose/b-user-testing-screen-1.jpg";
import imgUserTesting2 from "../../../img/bose/b-user-testing-screen-2.jpg";
import imgUserTesting3 from "../../../img/bose/b-user-testing-screen-3.jpg";
import imgUserTesting4 from "../../../img/bose/b-user-testing-screen-4.jpg";

//Videos
import PXPVideoIntro from "../../../img/bose/PXP-intro.mp4";
import PXPVideoTheme from "../../../img/bose/PXPVideoTheme.mp4";
import SOIntro from "../../../img/bose/SO-intro.mp4";

//SVGs
import EcomBestDesignPractices from "../../../icons/EcomBestDesignPractices";
import EcomPlatform from "../../../icons/EcomPlatform";

function ProjectBoseImmersive() {
  const context = useThemeUI();
  const { theme } = context;
  const modalActionItems = useModal();
  const modalPersona = useModal();

  let pathname = typeof window !== "undefined" ? window.location.pathname : "";
  const section1 = pathname + "#section1";
  const section2 = pathname + "#section2";
  const section3 = pathname + "#section3";
  const section4 = pathname + "#section4";

  const projectBgColor = theme?.colors?.boseProjectBgImmersive as string;
  const projectTextColor = theme?.colors?.boseProjectTextImmersive as string;
  const themeColor = theme?.colors?.boseProjectBgImmersive as string;
  const themeColorBg = theme?.colors?.background as string;

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const rect = heroSection.getBoundingClientRect();
        if (rect.bottom > 0) {
          document.body.classList.add("hero-visible");
          document.body.classList.remove("hero-hidden");
        } else {
          document.body.classList.add("hero-hidden");
          document.body.classList.remove("hero-visible");
        }
      } else {
        document.body.classList.add("hero-hidden");
        document.body.classList.remove("hero-visible");
      }
    };

    const heroSection = document.getElementById("hero-section");
    if (heroSection) {
      window.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      document.body.classList.add("hero-hidden");
      document.body.classList.remove("hero-visible");
    }
  }, []);

  return (
    <Layout>
      <ThemeColorSetter color={themeColor} colorBg={themeColorBg} />
      <main>
        <Header bgColor={projectBgColor} textColor={projectTextColor} />
        {/* On page navigation */}
        <Flex justifyContent="center">
          <Box variant="onPageNav" className="page-nav">
            <UAnchorLink
              to={section1}
              sx={{
                variant: "buttons.outline",
                backgroundColor: "transparent",
              }}
              title="Overview"
            >
              Overview
            </UAnchorLink>
            <UAnchorLink
              to={section2}
              sx={{
                variant: "buttons.outline",
                backgroundColor: "transparent",
              }}
              title="Problem statement"
            >
              Problem
            </UAnchorLink>
            <UAnchorLink
              to={section3}
              sx={{
                variant: "buttons.outline",
                backgroundColor: "transparent",
              }}
              title="Approach"
            >
              Approach
            </UAnchorLink>
            <UAnchorLink
              to={section4}
              sx={{
                variant: "buttons.outline",
                backgroundColor: "transparent",
              }}
              title="Result"
            >
              Result
            </UAnchorLink>
          </Box>
        </Flex>

        {/* Hero */}
        <div id="section1">
          <Hero
            projectType={"Case study"}
            title={"Immersive "}
            titleRow={"Brand Stories"}
            productType={"eCommerce, Website, UX Design"}
            titleClassName={"uppercase"}
            subtitle={
              "Bringing customers closer to sound in the absence of physical stores"
            }
            projectBg={projectBgColor}
            projectText={projectTextColor}
            shortDesc={
              <Fragment>
                {/* Develop digital experiences that bridge the gap left by
              the absence of physical stores.  */}
                Leveraging our design system to create immersive, user-centric
                online interactions that replicate the in-store experience and
                engage customers on a deeper level.
              </Fragment>
            }
            year={"2024"}
            role={<Fragment>Senior UX Designer</Fragment>}
            company={"BOSE"}
            roleDesc={
              <Fragment>
                I played a key role in guiding and collaborating with the CX
                optimization team, leading the development of a customer-centric
                information architecture for explore and product experience
                pages. Our strategy focused on leveraging immersive experiences
                driven by a passion for sound, aiming to build lasting customer
                relationships. Educating the user on the product’s possibilities
                and its usage through storytelling. Convince the user that the
                product is the right one for them as well as propose similar
                products that respond to their needs.
              </Fragment>
            }
            disciplines={
              <Fragment>
                Marketing &mdash; Content &mdash; Design &mdash; Development
                &mdash; CRO &mdash; SEO
              </Fragment>
            }
            challenge={
              <Fragment>
                Illustrating the power of sound by creating immersive
                experiences that encourage exploration, not just shopping,
                prioritizing the consumer's overall sound experience over mere
                transactions.
              </Fragment>
            }
            component={
              <PlaneScene
                coverColor={"#f0c844"}
                textColor={"silver"}
                urlTexture={"/models/textures/bose-cover-immersive-stories.jpg"}
                urlBackTexture={"/models/textures/bose-cover-immersive-stories-back.jpg"}
              />
            }
          />
        </div>
        <div id="section2">
          {/* Problem statement */}
          <Flex
            flexWrap="wrap"
            justifyContent="center"
            variant="container"
            id="immersive"
          >
            <Box width={[1 / 1]} variant="containerBigTitle">
              <ProjectFadeInTitle
                word1={"Desire"}
                word2={"preceds"}
                word2ClassName={"outline"}
                word3={"purchase"}
                className={"uppercase"}
              />
            </Box>
            <Box variant="containerTxt">
              {/* Problem statement */}
              <Heading as="h3" variant="catHeading">
                Problem statement
              </Heading>
              <Text
                as="p"
                mb={[4, 5]}
                variant="paragraphXL"
                fontWeight="medium"
              >
                In the absence of physical stores, customers are missing the hands-on experiences that result from exploring audio products in-person.
              </Text>

              <Heading as="h3" variant="catHeading">
                Context
              </Heading>

              <Text as="p" pb={[3, 4]} mb={[4, 5]}>
                Our UX strategy focused on creating a seamless shopping and
                checkout experience that converts customers. By using
                interactive and educational tools, we needed to empower users to
                meet their sound needs and engage them meaningfully,
                prioritizing their journey over brand promotion.
              </Text>

              {/* Navigational path */}
              <Heading as="h2" variant="bigTitleXS">
                Simplified navigational path
              </Heading>

              <Flex flexWrap="wrap">
                <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                  <Heading as="h3" variant="catHeading">
                    {/* Find */}
                  </Heading>
                  <Text as="p" pr={[0, 2, 4]} mb={[2, 3, 4]}>
                    <strong>SHOP</strong> <br />
                    Direct path to purchase via intuitive groupings.
                  </Text>
                </Box>
                <Box width={[1 / 1, 1 / 1, 1 / 3]} mb={[2, 3, 4]}>
                  <Heading as="h3" variant="catHeading">
                    {/* Discover */}
                  </Heading>
                  <Text as="p" pr={[0, 2, 4]} mb={[2, 3, 4]}>
                    <strong>EXPLORE</strong> <br />
                    Discover based on listening moments.
                  </Text>
                </Box>
                <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                  <Heading as="h3" variant="catHeading">
                    {/* Help */}
                  </Heading>
                  <Text as="p">
                    <strong>SUPPORT</strong> <br />
                    Best-in-class customer support.
                  </Text>
                </Box>
              </Flex>

              <Box width={[1 / 1]} mt={[4, 0, 0]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-new-site-structure.jpg"
                  alt="New site structure"
                />
              </Box>
              <Text as="span" variant="caption">
                The new site structure integrates analytics, user insights,
                content, and strategy to guide customers to products they love,
                outperforming the previous taxonomy according to user testing.
              </Text>
            </Box>

            <Box width={[1 / 1]} mb={[4, 3]}>
              <Flex
                flexWrap="wrap"
                variant="container"
                justifyContent="center"
                mb={[1, 1, 1]}
              >
                <Box width={[1 / 1, 1 / 1, 1 / 2]} variant="containerCard">
                  <Card
                    cardBg={theme.colors?.bigTitle as string}
                    cardText={theme.colors?.background as string}
                    title={"Sound Occasion"}
                    //subheading={"Cross-category groupings"}
                    desc={
                      <Fragment>
                        <Heading as="h3" variant="catHeading">
                          Explore
                        </Heading>
                        <Text as="p" variant="paragraphXL">
                          <strong>Cross-category groupings</strong>
                        </Text>
                        <Text as="p">
                          Bridge the digital-physical by allowing customers to
                          'try' and 'feel' products and discover the ideal
                          product for their sound needs.
                        </Text>
                      </Fragment>
                    }
                    info={
                      <Fragment>
                        <Heading as="h3" variant="catHeading">
                          User mindset
                        </Heading>
                        <Text as="p">
                          <strong>
                            "I don't know which product will meet my need."
                          </strong>
                        </Text>
                      </Fragment>
                    }
                  />
                </Box>
                <Box
                  width={[1 / 1, "auto"]}
                  p={[3]}
                  alignSelf="center"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text as="p" sx={{ color: theme.colors?.bigTitleQuiet }}>
                    vs.
                  </Text>
                </Box>
                <Box
                  width={[1 / 1, 1 / 1, 1 / 2]}
                  variant="containerCard"
                  alignItems="stretch"
                >
                  <Card
                    title={"PLP"}
                    //subheading={"Category overview"}
                    desc={
                      <Fragment>
                        <Heading as="h3" variant="catHeading">
                          Find
                        </Heading>
                        <Text as="p" variant="paragraphXL">
                          <strong>Category overview</strong>
                        </Text>
                        <Text as="p">
                          Provides a fast and guided way to identify the ideal
                          product from a diverse range, highlighting their
                          distinct features.
                        </Text>
                      </Fragment>
                    }
                    info={
                      <Fragment>
                        <Heading as="h3" variant="catHeading">
                          User mindset
                        </Heading>
                        <Text as="p">
                          <strong>
                            "I'm looking for some headphones."
                            <br />
                            <br />
                          </strong>
                        </Text>
                      </Fragment>
                    }
                  />
                </Box>
              </Flex>
            </Box>

            <Box width={[1 / 1]} mb={[4, 3]}>
              <Flex
                flexWrap="wrap"
                py={[3, 4]}
                variant="container"
                justifyContent="center"
                mb={[4, 5, 6]}
              >
                <Box width={[1 / 1, 1 / 1, 1 / 2]} variant="containerCard">
                  <Card
                    cardBg={theme.colors?.bigTitle as string}
                    cardText={theme.colors?.background as string}
                    title={"PXP"}
                    //subheading={"Flagship product"}
                    desc={
                      <Fragment>
                        <Heading as="h3" variant="catHeading">
                          Learn
                        </Heading>
                        <Text as="p" variant="paragraphXL">
                          <strong>Flagship product</strong>
                        </Text>
                        <Text as="p">
                          Center the site around a core group of products and
                          provide content that allows customers to immerse
                          themselves, fostering a sense of ownership.
                        </Text>
                      </Fragment>
                    }
                    info={
                      <Fragment>
                        <Heading as="h3" variant="catHeading">
                          User mindset
                        </Heading>
                        <Text as="p">
                          <strong>
                            "I'm looking for the best noise cancelling
                            headphones."
                          </strong>
                        </Text>
                      </Fragment>
                    }
                  />
                </Box>
                <Box
                  width={[1 / 1, "auto"]}
                  p={[3]}
                  alignSelf="center"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text
                    as="p"
                    sx={{ color: theme.colors?.bigTitleQuiet }}
                    textAlign="center"
                  >
                    vs.
                  </Text>
                </Box>
                <Box width={[1 / 1, 1 / 1, 1 / 2]} variant="containerCard">
                  <Card
                    title={"PDP"}
                    //subheading={"Product information"}
                    desc={
                      <Fragment>
                        <Heading as="h3" variant="catHeading">
                          Purchase
                        </Heading>
                        <Text as="p" variant="paragraphXL">
                          <strong>Product information</strong>
                        </Text>
                        <Text as="p">
                          Enable quick comprehension to help customers
                          confidently confirm their choices and make purchases.
                        </Text>
                      </Fragment>
                    }
                    info={
                      <Fragment>
                        <Heading as="h3" variant="catHeading">
                          user mindset
                        </Heading>
                        <Text as="p">
                          <strong>
                            "I need headphones for work, with specific
                            features."
                          </strong>
                        </Text>
                      </Fragment>
                    }
                  />
                </Box>
              </Flex>
            </Box>
          </Flex>

          <Flex
            flexWrap="wrap"
            justifyContent="center"
            variant="container"
            mb={[2, 2, 2, 2, 2]}
          >
            <Box variant="containerTxt">
              <Heading as="h2" variant="bigTitleXS">
                Customer insights
              </Heading>
              <Heading as="h3" variant="catHeading">
                Persona
              </Heading>
              <Text as="p" variant="paragraphXL" mb={[1, 2, 3]}>
                <strong>We serve the music lover</strong>
              </Text>
              <Text as="p" mb={[4]}>
                Our design decisions were guided by a persona deeply rooted in
                the love for music and technological enhancements. This persona,
                characterized by a strong desire to "feel the music," values
                quality and immersive sound experiences that allow them to
                listen critically, relax, and set the mood according to their
                preferences.
              </Text>
              <Box width={[1 / 1]} mb={[5, 6]}>
                <Card
                  eyebrow="User pain points"
                  subheading="Unable to visualize the benefits of a product in their daily music listening and entertainment routines"
                  //subheading="Visualizing the benefits of how products improve daily music listening and entertainment routines"
                  //img={imgUserTesting1}
                  modalLink={modalPersona}
                />
              </Box>
              {/* modalPersona */}
              {/* <Button
              mb={[5,6]}
              sx={{ variant: "buttons.primary", fontSize: [2, 2] }}
              onClick={modalPersona.toggle}
            >
              Learn more <Close />
            </Button>*/}

              <Modal
                isShown={modalPersona.isShown}
                hide={modalPersona.toggle}
                headerText="Pain points"
                modalContent={
                  <Fragment>
                    {/* <Heading as="h3" variant="catHeading">
                    Persona
                  </Heading> */}
                    <Text as="p" mt={[4]}>
                      Addressing the pain points of our target persona revealed
                      several challenges that affect their experience and
                      satisfaction.
                    </Text>
                    <ul>
                      <li>too many options</li>
                      <li>too engineered and serious</li>
                      <li>not understanding how products fit their life</li>
                      <li>difficult to evaluate many options</li>
                      {/* <li>passionate about music - cannot feel sound (mobile video)</li> */}
                    </ul>

                    <Text as="p" mb={[5, 6]}>
                      There is a gap in understanding how different products can
                      seamlessly integrate into their lifestyle, particularly in
                      terms of usability and practicality. This disconnect often
                      leaves them unable to visualize the benefits of a product
                      in their daily music listening and entertainment routines.
                    </Text>
                  </Fragment>
                }
              />

              <Heading as="h2" variant="bigTitleXS">
                User testing learnings
              </Heading>
              <Text as="p">
                We aimed to assess the effectiveness of the product experience
                page's storytelling in educating and encouraging potential
                buyers. Additionally, we sought to understand customer reactions
                to copy, imagery, and page rhythm to determine if anything is
                missing from the experience.
              </Text>
              <Text as="p" variant="caption">
                The interview was conducted in partnership with an external
                agency, guided by the UX strategy we developed.
              </Text>
            </Box>
          </Flex>

          <Carousel
            component1={
              <ChatBubble
                learning={
                  "Users express preferences for specific product colors and visuals."
                }
                quote={
                  <Fragment>
                    <Text as="p">
                      "It's nice to know there are colors, but I will say that
                      blue color is not as appealing as this neutral color."
                    </Text>
                  </Fragment>
                }
                author={"UserZoom participant"}
                img={imgUserTesting4}
              />
            }
            component2={
              <ChatBubble
                learning={"The copy creates confusion rather than clarity."}
                quote={
                  <Fragment>
                    <Text as="p">
                      "This is about noise cancellation but this is kind of a
                      confusing message."
                    </Text>
                  </Fragment>
                }
                author={"UserZoom participant"}
                img={imgUserTesting3}
              />
            }
            component3={
              <ChatBubble
                learning={"Need to skim trough information."}
                quote={
                  <Fragment>
                    <Text as="p">
                      "This information seems important but it's hard to skim
                      this."
                    </Text>
                  </Fragment>
                }
                author={"UserZoom participant"}
                img={imgUserTesting1}
              />
            }
            component4={
              <ChatBubble
                learning={
                  "Feature descriptions are prone to misinterpretation."
                }
                quote={
                  <Fragment>
                    <Text as="p">
                      "With 11 listening modes, can I create various profiles
                      for the noise cancellation... one for work, one for home,
                      etc...?"
                    </Text>
                  </Fragment>
                }
                author={"UserZoom participant"}
                img={imgUserTesting2}
              />
            }
          />
        </div>

        <div id="section3">
          <Flex
            flexWrap="wrap"
            justifyContent="center"
            variant="container"
            mt={[3, 4, 5, 6, 7]}
          >
            <Box width={[1 / 1, 1 / 1, 7 / 12]} variant="containerBigTitle">
              <ProjectFadeInTitle
                word1={"Sound"}
                word2ClassName={"outline"}
                word3={"Occasion"}
                className={"uppercase"}
              />
            </Box>
            <Box width={[1 / 1, 1 / 1, 5 / 12]} variant="containerBigTitleImg">
              <Heading as="h3" variant="catHeading">
                Themed shopping
              </Heading>
              <EcomPlatform />
              <Text as="span" variant="caption">
                Uncover customer needs and priorities to identify the Bose
                product for the sound occasion (activity) that matters to them.
              </Text>
            </Box>
            <Box width={[1 / 1]} mt={[3, 4, 5]} variant="containerTxt">
              <Heading as="h3" variant="catHeading">
                Explore
              </Heading>
              <Heading as="h2" variant="bigTitleXS">
                What are Sound Occasions?
              </Heading>
              <Text as="p" mb={[3, 4, 5]}>
                Sound occasion pages respond to the "listening moments"
                strategy, tapping on our{" "}
                <strong>
                  learnings about how customers use their products in specific
                  contexts.
                </strong>
              </Text>
              <Box width={[1 / 1]} mb={[4, 5, 6]} color="bigTitle">
                <UxStrategyDiagram />
              </Box>
              <Heading as="h2" variant="bigTitleXS">
                Shop by Occasion
              </Heading>

              <Text as="p" mb={[4]}>
                These pages allow us to showcase multiple product categories
                around one use case, explain how uniquely Bose technology
                delivers better sound, and tell a more engaging story that goes
                beyond just product.
                <ul>
                  <li>
                    <strong>Guide users</strong> that don’t know much or haven’t
                    yet made up their mind about a specific range of products
                  </li>
                  <li>
                    <strong>Showcase currated products</strong> based on
                    specific use cases around occasions locations and locations
                  </li>
                </ul>
              </Text>

              <Box width={[1 / 1]} mb={[4, 5, 6]}>
                <Flex
                  flexWrap="wrap"
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <Box width={[1 / 1, 1 / 2]} mb={[4, 0, 0]}>
                    <Box maxWidth="390px" alignItems="self-end" mr={[0, 4]}>
                      <UxStrategyDiagramSO />
                    </Box>
                  </Box>
                  <Box width={[1 / 1, 1 / 2]} pr={[0, 3, 4]}>
                    <Text as="p" variant="paragraphXL">
                      <strong>Simply and go</strong>
                    </Text>
                    <Text as="p">
                      I developed an initial concept to guide our information
                      architecture, serving as our north star. With limited time
                      for research, we leveraged the existing knowledge and
                      relied on our expertise and best judgment.
                    </Text>
                  </Box>
                </Flex>
              </Box>

              <Heading as="h2" variant="bigTitleXS">
                Goals
              </Heading>
              <Text as="p" mb={[4, 5, 6]}>
                <ul>
                  <li>
                    Leverage the explore section to drive more organic traffic
                  </li>
                  <li>Surface relevant content to website visitors</li>
                  <li>
                    Improve page exploration, discoverability and better connect
                    to other areas of the website
                  </li>
                </ul>
              </Text>

              <Heading as="h2" variant="bigTitleXS">
                Action items
              </Heading>
              <Text as="p" mb={[3]}>
                In order to achieve our goals we’ve covered the following action
                items
                <ul>
                  <li>
                    <strong>Defined purpose</strong> of SO/PXP Pages to
                    highlight products, integrate categories and drive emotional
                    engagement.
                  </li>
                  <li>
                    <strong>Audit & optimization of motion</strong> to assess
                    animation and transitions, establish motion principles and
                    create modular reusable components.
                  </li>
                  <li>
                    <strong>Guide development</strong> by setting rules and
                    motion guidelines, balanced page elements and improve UX
                    contextually.
                  </li>
                </ul>
              </Text>
              {/* ModalActionItems */}
              <Button
                mb={[5, 6]}
                sx={{ variant: "buttons.primary", fontSize: [2, 2] }}
                onClick={modalActionItems.toggle}
              >
                Learn more <Close />
              </Button>

              <Modal
                isShown={modalActionItems.isShown}
                hide={modalActionItems.toggle}
                headerText="Action items"
                modalContent={
                  <Fragment>
                    {/* Purpose */}
                    <Heading as="h3" variant="catHeading">
                      Step #1
                    </Heading>
                    <Heading
                      as="h2"
                      variant="bigTitleXS"
                      sx={{ color: "inherit" }}
                    >
                      Purpose
                    </Heading>
                    <Text as="p" mb={[3, 4]}>
                      <strong>Showcase products:</strong> Highlight our products
                      using the "listening moments" strategy to cater to
                      specific user needs.
                      <br />
                      <strong>Cross-category integration:</strong> Demonstrate
                      the synergy between different products in various use
                      cases to illustrate 'Better Together' scenarios.
                      <br />
                      <strong>Emotional engagement:</strong> Craft narratives
                      that create emotional impact and seamless user flow.
                    </Text>
                    <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
                      <StaticImage
                        src="../../../img/bose/b-action-items-purpose.jpg"
                        alt="Purpose example"
                      />
                    </Box>
                    {/* Audit */}
                    <Heading as="h3" variant="catHeading">
                      Step #2
                    </Heading>
                    <Heading
                      as="h2"
                      variant="bigTitleXS"
                      sx={{ color: "inherit" }}
                    >
                      Audit & optimization of motion
                    </Heading>
                    <Text as="p" mb={[3, 4]}>
                      <strong>Motion audit:</strong> Review and assess the
                      purpose of animations and transitions. Identify where
                      motion is lacking and where it's excessive. Evaluate
                      timing curves, choreography patterns, and effect patterns
                      such as scale, opacity, and elevation.
                      <br />
                      <strong>Define motion principles:</strong> Establish
                      guidelines for motion design including easing, duration,
                      and effects. Distinguish between micro and macro
                      animations and their appropriate contexts.
                      <br />
                      <strong>Develop motion building blocks:</strong> Create
                      modular, reusable components focused on the interaction of
                      object, time, and effect. These should include ease,
                      duration, object effects (start/end states), and
                      choreography.
                    </Text>
                    <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
                      <StaticImage
                        src="../../../img/bose/b-action-items-audit.jpg"
                        alt="Audit example"
                      />
                    </Box>
                    {/* Guideline development */}
                    <Heading as="h3" variant="catHeading">
                      Step #3
                    </Heading>
                    <Heading
                      as="h2"
                      variant="bigTitleXS"
                      sx={{ color: "inherit" }}
                    >
                      Guide development
                    </Heading>
                    <Text as="p" mb={[3, 4]}>
                      <strong>Create motion guidelines:</strong> Formulate
                      principles that guide the usage of motion in UX,
                      addressing when and how animations should be employed.
                      <br />
                      <strong>Page rhythm:</strong> Analyze the ratio of
                      animated to static elements to maintain balance and
                      prevent overstimulation. <br />
                      <strong>Contextual UX enhancements:</strong> Consider
                      principles that enhance user experience contextually:
                      <ul>
                        <li>
                          Strengthen affordances and action feedback where
                          necessary.
                        </li>
                        <li>Address user disorientation in navigation.</li>
                        <li>
                          Pinpoint and rectify fall-offs during onboarding
                          processes.
                        </li>
                      </ul>
                    </Text>
                    <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
                      <StaticImage
                        src="../../../img/bose/b-action-items-guide-development.jpg"
                        alt="Guide development example"
                      />
                    </Box>
                  </Fragment>
                }
              />

              {/* Solution & recommendations */}
              <Heading as="h2" variant="bigTitleXS">
                Proposed solutions & recommendations for 'explore' pages
              </Heading>
              <Text as="p" mb={[3, 4]}>
                Our solution centered on developing a story-driven, actionable,
                user-centric page that leverages technology enabled by real-life
                situations to guide users to products.
                <br />
                <strong>
                  {" "}
                  This approach prioritizes user behavior as the driver of
                  technology, with products playing a supportive role.
                </strong>
              </Text>
              <Heading as="h2" variant="catHeading">
                Key ingredients
              </Heading>
              <Text as="p" mb={[3, 4]}>
                <ul>
                  <li>Focus around one specific occasion/activity.</li>
                  <li>Set context by provide familiar locations.</li>
                  <li>Use technology benefits to engage users.</li>
                  <li>
                    Showcases cross-category groupings based on shared
                    technology.{" "}
                  </li>
                  <li>Drive for familiarity and consistency.</li>
                </ul>
              </Text>
              <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/so-ingredients.jpg"
                  alt="Key ingredients"
                />
              </Box>
              {/* IA */}
              <Heading as="h3" variant="catHeading">
                Highlight
              </Heading>
              <Heading as="h2" variant="bigTitleXS">
                Information Architecture
              </Heading>
              <Text as="p" mb={[3, 4, 5]}>
                While staying true to our overarching goal, we proposed the
                adoption of a formula that not only enhances user exploration
                but also simplifies maintenance for our internal teams.{" "}
              </Text>
              <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-ia-so.jpg"
                  alt="Information Architecture - SO"
                />
              </Box>
              {/* Progressive disclosure */}
              <Heading as="h2" variant="bigTitleXS">
                Progressive disclosure
              </Heading>
              <Text as="p" mb={[3, 4, 5]}>
                Present information gradually, only as it becomes relevant or
                necessary for the user.
                <ul>
                  <li>reduce cognitive load</li>
                  <li>decluttered UI</li>
                  <li>user has control</li>
                </ul>
              </Text>
              <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-progressive-disclosure-so.jpg"
                  alt="Progressive disclosure"
                />
              </Box>
              {/* On page navigation */}
              <Heading as="h2" variant="bigTitleXS">
                Clear entry points
              </Heading>
              <Text as="p" mb={[3, 4, 5]}>
                This approach not only caters to diverse customer interests but
                also aligns with the trend of providing curated and thematic
                product collections to enhance the overall online shopping
                journey.
                <ul>
                  <li>familiar groupings that resonated to users</li>
                  <li>consistent focus on ocassion</li>
                </ul>
              </Text>
              <Box width={[1 / 1]} mb={[4, 5, 6]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-entry-points-so.jpg"
                  alt="Progressive disclosure"
                />
              </Box>

              {/* Proof of concept  */}
              <Box width={[1 / 1]}>
                <Heading as="h3" variant="catHeading">
                  Highlight
                </Heading>
                <Heading as="h2" variant="bigTitleXS">
                  Proof of concept
                </Heading>
                <Flex flexWrap="wrap" justifyContent="center">
                  <Box width={[1 / 1, 1 / 2]} pr={[0, 3, 4]}>
                    <Text as="p" mb={[3]}>
                      Leveraging our information architecture and adhering to
                      our motion principles and guidelines, we aimed to rapidly
                      develop a testing prototype that closely resembles the
                      final product. This self-initiated effort proved
                      successful during discussions with stakeholders, even if
                      it was a work in progress when demoing it.
                    </Text>
                    <Link
                      variant="aLink"
                      href="https://bose-8230a6-af49cf35884748b3b2214c99d83.webflow.io/explore/at-home"
                      target="_blank"
                      rel="noopener norefferer"
                      mb={"0px"}
                    >
                      View prototype *
                    </Link>
                    <Text as="span" variant="caption">
                      <strong>*NOTE</strong> Only chosen ones have access so be
                      ready for a password.
                    </Text>
                  </Box>
                  <Box width={[1 / 1, 1 / 2]}>
                    <Box maxWidth="390px" alignItems="self-end">
                      <Box variant="wrapperVideoPortrait">
                        <video autoPlay muted loop playsInline>
                          <source src={SOIntro} type="video/mp4" />
                        </video>
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Flex>

          <Flex flexWrap="wrap" justifyContent="center" variant="container">
            <Box width={[1 / 1, 1 / 1, 7 / 12]} variant="containerBigTitle">
              <ProjectFadeInTitle
                word1={"Product"}
                word3={"experience"}
                className={"uppercase"}
              />
            </Box>
            <Box width={[1 / 1, 1 / 1, 5 / 12]} variant="containerBigTitleImg">
              <Heading as="h3" variant="catHeading">
                Discover
              </Heading>
              <EcomBestDesignPractices />
              <Text as="span" variant="caption">
                Get customers closer to sound and educate by letting them feel,
                try and immerse themselves in the products, while building
                mental ownership.
              </Text>
            </Box>
            <Box variant="containerTxt">
              {/* PXP */}
              <Heading as="h3" variant="catHeading">
                Learn
              </Heading>
              <Heading as="h2" variant="bigTitleXS">
                What are PXPs?
              </Heading>
              <Text as="p" mb={[3, 4, 5]}>
                The PXPs (Product Experience Pages) were developed to address a
                recognized gap in our website's user experience.
              </Text>
              <Box width={[1 / 1]} mb={[5, 6]}>
                <UxStrategyDiagramPXP />
              </Box>
              <Heading as="h2" variant="bigTitleXS">
                PXP strategy
              </Heading>
              <Text as="p" mb={[3, 4, 5]}>
                <strong>Why PXPs?</strong>
                <br />
                We required a solution to establish hierarchy within our product
                lineup and effectively convey the story of our flagship products
                in a more immersive manner. This solution needed to evoke an
                emotional response and facilitate a "show and tell" experience,
                enabling users to explore, interact with, and develop an
                affinity for the product before transitioning into the purchase
                phase.
              </Text>

              <Box width={[1 / 1]} mb={[4, 5, 6]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-flow-pxp.jpg"
                  alt="PXP flow diagram"
                />
              </Box>

              <Heading as="h2" variant="bigTitleXS">
                Goals
              </Heading>
              <Text as="p" mb={[4, 5, 6]}>
                We aimed to address specific UX challenges, focusing on
                enhancing usability, performance, and emotional engagement
                across the platform.
                <ul>
                  <li>
                    reduce or eliminate animations on components where usability
                    is compromised by excessive motion
                  </li>
                  <li>
                    Surface relevant content to website visitors + update
                    content to provide richer context and link imagery with more
                    emotionally resonant copy
                  </li>
                  <li>
                    Improve page exploration, discoverability and better connect
                    to other areas of the website
                  </li>
                  <li>
                    improve performance and user focus by expanding the use of
                    static components
                  </li>
                  <li>
                    refine scroll mechanics and timing adjustments to enhance
                    performance and user experience on both desktop and mobile
                  </li>
                  <li>
                    ensure video animations behave consistently across the site,
                    displaying text concurrently with video playback to optimize
                    page load times. Eliminate scroll-triggered and parallax
                    effects.
                  </li>
                  <li>
                    reduce unwanted negative space to improve content density
                    and user engagement, without disrupting the overall UI in
                    special use cases.
                  </li>
                </ul>
              </Text>
              {/* IA PXP */}
              <Heading as="h3" variant="catHeading">
                Highlight
              </Heading>
              <Heading as="h2" variant="bigTitleXS">
                Information Architecture - PXP
              </Heading>
              <Text as="p" mb={[3, 4, 5]}>
                Sound Occasion approach to a formula proved to be succesful thus
                we approached the same solution for our PXP pages.{" "}
              </Text>
              <Box width={[1 / 1]} mb={[3, 4, 5]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-ia-pxp.jpg"
                  alt="Information Architecture PXP"
                />
              </Box>
              <Box width={[1 / 1]} mb={[5, 6]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-ia-to-wireframe-pxp.jpg"
                  alt="Information Architecture PXP"
                />
              </Box>

              {/* Local navigation */}
              <Heading as="h2" variant="bigTitleXS">
                Local navigation
              </Heading>
              <Text as="p" mb={[3, 4, 5]}>
                <strong>Valuable orientation and wayfinding aid</strong> &mdash;
                the local navigation indicates to users where they are and what
                other content is nearby in an information hierarchy.
              </Text>

              <Flex flexWrap="wrap" mb={[3]}>
                <Box width={[1 / 1, 1 / 1, 1 / 2]}>
                  <Heading as="h3" variant="catHeading">
                    Hypothesis
                  </Heading>
                  <Text as="p" pr={[0, 2, 4]} mb={[3, 4]}>
                    The inability of users to easily access and understand
                    product overviews and distinctions may result in lower
                    engagement and reduced purchasing intent.
                  </Text>
                </Box>
                <Box width={[1 / 1, 1 / 1, 1 / 2]} mb={[3, 4]}>
                  <Heading as="h3" pl={[0, 2, 4]} variant="catHeading">
                    Solution
                  </Heading>
                  <Text as="p" pl={[0, 2, 4]} mb={[3, 4]}>
                    Provide straightforward access to product categories and
                    clear explanations of their relevance and differences to
                    enhance user understanding and engagement.
                  </Text>
                </Box>
              </Flex>

              <Box width={[1 / 1]} mb={[3, 4, 5]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-local-navigation-flow-pxp.jpg"
                  alt="Local navigation structure"
                />
              </Box>
              <Box width={[1 / 1]} mb={[3, 4, 5]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-local-navigation-proposals-pxp.jpg"
                  alt="Local navigation proposals"
                />
              </Box>
              <Box width={[1 / 1]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-local-navigation-pxp.jpg"
                  alt="Local navigation proposals"
                />
              </Box>
              <Text as="span" variant="caption" mb={[5, 6]}>
                Mobile-first proposals prepared for user testing.
              </Text>

              {/* Communicating benefits */}
              <Heading as="h3" variant="catHeading">
                Highlight
              </Heading>
              <Heading as="h2" variant="bigTitleXS">
                Adding depth when communicating benefits
              </Heading>
              <Text as="p" mb={[3, 4, 5]}>
                Beyond the page structure we introduced an approach that would
                facilitate the communication of our product’s benefits. This
                method showcases layered information, allowing users to explore
                topics of interest in greater depth at their own pace.
              </Text>
              <Text as="p" variant="paragraphXL" mb={[1, 2, 3]}>
                <strong>An invitation to discover</strong>
              </Text>
              <Flex flexWrap="wrap" mb={[3]}>
                <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                  <Heading as="h3" variant="catHeading">
                    Show & tell
                  </Heading>
                  <Text as="p" pr={[0, 2, 4]} mb={[2, 3, 4]}>
                    <strong>Excite &#8594; Present</strong> <br />
                  </Text>
                </Box>
                <Box width={[1 / 1, 1 / 1, 1 / 3]} mb={[2, 3, 4]}>
                  <Heading as="h3" variant="catHeading">
                    Learn
                  </Heading>
                  <Text as="p" pr={[0, 2, 4]} mb={[2, 3, 4]}>
                    <strong>Educate &#8594; Expand</strong> <br />
                  </Text>
                </Box>
                <Box width={[1 / 1, 1 / 1, 1 / 3]}>
                  <Heading as="h3" variant="catHeading">
                    Build trust
                  </Heading>
                  <Text as="p">
                    <strong>Convince &#8594; Emphasize</strong> <br />
                  </Text>
                </Box>
              </Flex>
              <Box width={[1 / 1]} variant="wrapperImg">
                <StaticImage
                  src="../../../img/bose/b-pxp-present-educate-convince.jpg"
                  alt="Wireframe with annotations for Excite / Educate / Convince"
                />
              </Box>
              <Text as="p" variant="caption" mb={[3, 4, 5]}>
                Wireframes were annotated to support the 'Excite, Educate,
                Convince' strategy, providing clear guidance on each step of the
                approach.
              </Text>
            </Box>
          </Flex>
        </div>

        <div id="section4">
          <Flex flexWrap="wrap" justifyContent="center" variant="container">
            {/* POV PXP */}
            <Box width={[1 / 1]}>
              <Box mx="auto" variant="containerTxt">
                <Heading as="h3" variant="catHeading">
                  Highlight
                </Heading>
                <Heading as="h2" variant="bigTitleXS">
                  Proof of concept
                </Heading>
                <Text as="p">
                  The prototype highlights the present/expand/emphasize
                  approach. Our objective was to gather directional feedback
                  from users, which will guide us in making further incremental
                  changes.
                </Text>
              </Box>
              <Flex flexWrap="wrap" justifyContent="center" variant="container">
                <Box width={[1 / 1, 4 / 12]} maxWidth="390px" mx={[0, 4]}>
                  <Box variant="wrapperVideoPortrait">
                    <video autoPlay muted loop playsInline>
                      <source src={PXPVideoIntro} type="video/mp4" />
                    </video>
                  </Box>
                  <Text as="p" variant="caption">
                    The prototype is a work-in-progress, with some content still
                    pending, yet it has proven successful as a POV (point of
                    validation) with stakeholders.
                  </Text>
                </Box>
                <Box width={[1 / 1, 8 / 12]}>
                  <Box width={[1 / 1]} variant="wrapperVideo">
                    <video autoPlay muted loop playsInline>
                      <source src={PXPVideoTheme} type="video/mp4" />
                    </video>
                  </Box>
                </Box>
              </Flex>
            </Box>

            {/* Deliverables */}
            <Box variant="containerTxt">
              <Heading as="h3" variant="catHeading">
                The End
              </Heading>
              <Heading as="h2" variant="bigTitleXS">
                Deliverables
              </Heading>
              <ul>
                <li>Design System Guidelines & Recommedations</li>
                <li>UX Strategy & Approach</li>
                <li>Information Architecture</li>
                <li>Documentation</li>
                <li>
                  Prototyping &mdash; Webflow &nbsp;
                  <Link
                    variant="aLink"
                    href="https://bose-8230a6-af49cf35884748b3b2214c99d83.webflow.io/explore/at-home"
                    target="_blank"
                    rel="noopener norefferer"
                    mb={"0px"}
                  >
                    Explore 'At Home'
                  </Link>
                  &nbsp; | &nbsp;
                  <Link
                    variant="aLink"
                    href="https://bose-8230a6-af49cf35884748b3b2214c99d83.webflow.io/pxp/ultra-open-earbuds"
                    target="_blank"
                    rel="noopener norefferer"
                    mb={"0px"}
                  >
                    PXP 'Ultra Open Earbuds'
                  </Link>
                </li>
              </ul>
              <Heading as="h2" variant="bigTitleXS" mt={[3, 4, 5]}>
                Personal Note
              </Heading>
              <Text as="p">
                In large, global companies, it's crucial to recognize that
                change occurs incrementally and can be challenging to implement.
                Celebrating small victories is essential to maintain momentum
                and encourage ongoing progress.
              </Text>
              <Heading as="h3" variant="catHeading" mt={[3, 4, 5]}>
                Out there
              </Heading>

              <Link
                variant="buttons.primary"
                href="https://bose-8230a6-af49cf35884748b3b2214c99d83.webflow.io/explore/at-home"
                target="_blank"
                rel="noopener norefferer"
                py={[3]}
                my={[3, 4]}
              >
                View prototype
              </Link>
            </Box>
          </Flex>
        </div>
        <CompanyProjects title="BOSE" />
        <ProjectTitleListing />
        <BackToTop />
        <Footer />
      </main>
    </Layout>
  );
}

export default ProjectBoseImmersive;
